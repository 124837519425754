import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import {
  fragmentPageIconBox,
  Sections,
  PrismicSection,
} from '../components/Sections';
import { FluidImage } from '../components/Trainings/types';

interface Props {
  data: {
    prismic: {
      page: {
        header_title: string;
        header_subtitle: string;
        header_imageSharp?: FluidImage;
        body: PrismicSection[];
      };
    };
  };
}

export default function Page({ data }: Props) {
  const {
    prismic: {
      page: { header_title, header_subtitle, header_imageSharp, body },
    },
  } = data;
  return (
    <Layout
      heroImage={header_imageSharp}
      subtitle={header_subtitle}
      title={header_title}
    >
      <Seo title={`CRPM - ${header_title}`} />
      <Sections data={body} />
    </Layout>
  );
}

export const query = graphql`
  query getPage($uid: String!, $lang: String!) {
    prismic {
      page(uid: $uid, lang: $lang) {
        header_title
        header_subtitle
        header_image
        header_imageSharp {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        body {
          ...PageIconBox
        }
      }
    }
  }
`;

Page.fragments = [fragmentPageIconBox];
